interface QRCodeProps {
  url: string;
  imgSrc: string;
}
const DISPLAY_SIZE = { width: 75, height: 101 };

export const QRCode: React.FC<QRCodeProps> = ({ url, imgSrc }) => {
  return (
    <tr>
      <td>
        <table cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td valign="top">
                <table cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td>
                        <a
                          href={url}
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                          rel="noopener noreferrer"
                        >
                          <img
                            src={imgSrc}
                            alt="QR code"
                            style={{
                              display: 'block',
                              height: `${DISPLAY_SIZE.height}px`,
                              width: `${DISPLAY_SIZE.width}px`,
                            }}
                            height={DISPLAY_SIZE.height}
                            width={DISPLAY_SIZE.width}
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};
