import { ContactInfo } from '@/components/Templates/EmailSignature/ContactInfo';
import { JobTitle } from '@/components/Templates/EmailSignature/JobTitle';
import { NameTitle } from '@/components/Templates/EmailSignature/NameTitle';
import { ProfileImage } from '@/components/Templates/EmailSignature/ProfileImage';
import { QRCode } from '@/components/Templates/EmailSignature/QRCode';
import { SocialIcons } from '@/components/Templates/EmailSignature/SocialIcons';
import { IEmailSignature, IEmailSignatureAssets } from '@/types/IOrganisation';
import { IProfileSignature } from '@/types/IProfile';

export const supportedClients = ['gmail', 'outlook', 'source-code'] as const;
export type SupportedClient = (typeof supportedClients)[number];

interface PreviewProps {
  profile: IProfileSignature;
  signature: IEmailSignature;
  assets: IEmailSignatureAssets;
  clientType?: SupportedClient;
}

const addRef = (url: string, ref: string) => {
  const urlObj = new URL(url);
  urlObj.searchParams.set('ref', ref);
  return urlObj.toString();
};

const Preview = ({
  profile,
  signature,
  assets,
  clientType = 'gmail',
}: PreviewProps) => {
  const urlForQrCode = addRef(profile.profile_page_url, 'email-signature-qr');
  const urlForProfileImage = addRef(
    profile.profile_page_url,
    'email-signature-profile-image',
  );

  const fullName = [profile.first_name, profile.middle_name, profile.last_name]
    .filter(Boolean)
    .join(' ');

  return (
    <table
      dir="ltr"
      width="600"
      style={{ marginTop: '16px' }}
      cellSpacing="0"
      cellPadding="0"
    >
      <tr>
        <td>
          <table cellSpacing="0" cellPadding="0">
            <tr>
              {signature.show_profile_image && (
                <ProfileImage
                  profilePageUrl={urlForProfileImage}
                  imgSrc={assets?.profile_image_url}
                  blurHash={profile.photo?.blur_hash}
                />
              )}
              <td valign="top">
                <table cellSpacing="0" cellPadding="0">
                  <tr>
                    <td>
                      <table cellSpacing="0" cellPadding="0">
                        <tr>
                          <td valign="top">
                            <table cellSpacing="0" cellPadding="0">
                              <tr>
                                <td
                                  style={
                                    signature.show_qr_code
                                      ? { paddingBottom: '12px' }
                                      : {}
                                  }
                                >
                                  <table cellSpacing="0" cellPadding="0">
                                    <tr>
                                      <td valign="top">
                                        <table cellSpacing="0" cellPadding="0">
                                          {signature.show_full_name &&
                                            fullName && (
                                              <tr>
                                                <NameTitle
                                                  name={fullName}
                                                  textColor={
                                                    signature.primary_text_color
                                                  }
                                                />
                                              </tr>
                                            )}

                                          <JobTitle
                                            jobTitle={
                                              signature.show_job_title
                                                ? profile.job_title
                                                : null
                                            }
                                            company={
                                              signature.show_company_name
                                                ? profile.company_name
                                                : null
                                            }
                                            textColor={signature.text_color}
                                          />
                                          {signature.show_social_links && (
                                            <SocialIcons
                                              socialLinks={
                                                profile.profile_social_links
                                              }
                                            />
                                          )}
                                          <tr>
                                            <td
                                              style={{
                                                height: '0px',
                                                paddingBottom: '16px',
                                              }}
                                            >
                                              <ContactInfo
                                                profile={profile}
                                                signature={signature}
                                                clientType={clientType}
                                              />
                                            </td>
                                          </tr>
                                          {signature.show_qr_code && (
                                            <QRCode
                                              imgSrc={assets.qr_code_url}
                                              url={urlForQrCode}
                                            />
                                          )}
                                          <tr></tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {signature.disclaimer_text && (
                                <tr>
                                  <td
                                    style={{
                                      color: '#848083',
                                      fontSize: '10px',
                                      fontFamily: 'Verdana, Geneva, sans-serif',
                                      fontWeight: 400,
                                      lineHeight: '21px',
                                      height: '21px',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {signature.disclaimer_text}
                                  </td>
                                </tr>
                              )}
                              <tr></tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  );
};

export default Preview;
