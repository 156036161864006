import { useEffect, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';

import { EyeDropperIcon } from '@heroicons/react/20/solid';

import { classNames } from '@/helpers/strings';

export default function ColorPicker({
  color,
  setColor,
}: {
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [pickerPosition, setPickerPosition] = useState<'up' | 'down'>('down');

  const containerRef = useRef<HTMLDivElement>(null);

  function calculatePickerPosition() {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const vh = window.innerHeight;
      const scrollPosition = window.scrollY;

      const spaceBelow = vh - rect.bottom;
      const spaceAbove = rect.top - scrollPosition;

      const pickerHeight = 375;

      if (spaceBelow < pickerHeight && spaceAbove > spaceBelow) {
        if (pickerPosition !== 'up') {
          setPickerPosition('up');
        }
      } else {
        if (pickerPosition !== 'down') {
          setPickerPosition('down');
        }
      }
    }
  }

  useEffect(() => {
    calculatePickerPosition();

    window.addEventListener('scroll', calculatePickerPosition);
    window.addEventListener('resize', calculatePickerPosition);

    return () => {
      window.removeEventListener('scroll', calculatePickerPosition);
      window.removeEventListener('resize', calculatePickerPosition);
    };
  }, [calculatePickerPosition]);

  function handleHexChange(hex: string) {
    setColor(hex);
  }

  return (
    <div ref={containerRef} className="relative flex space-x-2">
      <div className="relative flex rounded-md">
        <span
          className="inline-flex items-center px-5 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
          style={{ backgroundColor: color }}
        />
        <span className="flex-1 p-2 border shadow-sm rounded-r-md w-24">
          {color}
        </span>
      </div>
      <button
        type="button"
        onClick={() => setDisplayColorPicker(true)}
        className="appearance-none"
      >
        <EyeDropperIcon className="w-5 h-5 text-brand-500" />
      </button>

      {displayColorPicker && (
        <div
          className={classNames(
            'absolute right-0 z-10 w-min',
            pickerPosition === 'up' ? 'bottom-12' : 'top-12',
          )}
        >
          <div
            className="fixed inset-0 -z-10"
            onMouseDown={() => setDisplayColorPicker(false)}
          />
          <div className="space-y-4 p-3 bg-white border border-gray-300 shadow-lg rounded-md">
            <HexColorPicker color={color} onChange={handleHexChange} />
            <div className="flex flex-col">
              <span className="text-sm text-gray-700">HEX Code</span>
              <HexColorInput
                className="rounded-md bg-gray-50 border-gray-300 focus:ring-brand-500"
                placeholder="HEX Code"
                color={color}
                onChange={handleHexChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
